import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  error: false,
  photos: {},
  ids: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PHOTOS_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.FETCH_PHOTOS_SUCCESS:
      return {
        ...state,
        photos: { ...state.photos, ...action.photos },
        ids: action.ids,
        loading: false,
        error: false
      };
    case actionTypes.FETCH_PHOTOS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case actionTypes.ADD_PHOTO_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_PHOTO_SUCCESS:
      return {
        ...state,
        photos: { [action.id]: action.photo, ...state.photos },
        ids: [action.id, ...state.ids],
        loading: false,
        error: false
      };
    case actionTypes.ADD_PHOTO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case actionTypes.DELETE_PHOTO_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_PHOTO_SUCCESS:
      let updatedPhotos = { ...state.photos };
      delete updatedPhotos[action.id];
      let updatedIds = state.ids.filter(id => id !== action.id);
      return {
        ...state,
        photos: { ...updatedPhotos },
        ids: updatedIds,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_PHOTO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};
export default reducer;
