import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  font: inherit;
  font-weight: 600;
  margin-top: 10px;
  padding: 7px 44px;
  background-color: ${props =>
    props.bgColor ? props.theme.colors[props.bgColor].main : "transparent"};
  color: ${props =>
  props.fontColor ? props.theme.colors[props.fontColor].main : "transparent"};
  @media only screen and (${props => props.theme.breakpoints.mobileMmin}) and (${props => props.theme.breakpoints.mobileLandscape}){
    display: none;
  }

  &:hover {
    background-color: ${props =>
    props.bgColor === "primary"
      ? props.theme.colors[props.bgColor].light ||
      props.theme.colors[props.bgColor].dark
      : props.bgColor === "tertiary"
        ? props.theme.colors[props.bgColor].light ||
        props.theme.colors[props.bgColor].main :
        "transparent"};
    color: ${props =>
    props.fontColor === "primary"
      ? props.theme.colors[props.fontColor].dark ||
      props.theme.colors[props.fontColor].light
      : props.theme.colors.secondary.dark ||
      props.theme.colors.secondary.light};
  }

  &:disabled {
    background-color: #eee;
    color: #000;
    cursor: unset;
    opacity: 0.5;
  }
`;

const Button = props => {
  return (
    <StyledButton
      disabled={props.disabled}
      className={[...props.classes].join(" ")}
      onClick={props.clicked}
      bgColor={props.bgColor}
      fontColor={props.fontColor}
    >
      {props.children}
    </StyledButton>
  );
};

export default Button;

Button.propTypes = {
  clicked: PropTypes.func.isRequired,
  bgColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired
};

Button.defaultProps = {
  classes: []
};
