import React, { useEffect, Suspense, lazy } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Theme from "../hoc/theme";
import Header from "../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../store/actions";
import Spinner from "../components/UI/Spinner/Spinner";
import Terms from "./Terms/Terms";

const AsyncWelcomePage = lazy(() => import("./WelcomePage/WelcomePage"));
const AsyncArtistPage = lazy(() => import("./ArtistPage/ArtistPage"));
const AsyncAdminPage = lazy(() => import("./AdminPage/AdminPage"));
const AsyncLoginPage = lazy(() => import("./LoginPage/LoginPage"));

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);

  const onFetchUser = () => dispatch(actions.fetchUser());
  const onFetchPhotos = () => dispatch(actions.fetchPhotos());
  const onFetchArtists = () => dispatch(actions.fetchArtists());
  const onFetchOpeningHours = () => dispatch(actions.fetchOpeningHours());
  const onFetchUsers = () => dispatch(actions.fetchUsers());
  const onFetchPromotions = () => dispatch(actions.fetchPromotions());
  const onFetchInstagram = () => dispatch(actions.fetchInstagram());

  useEffect(() => {
    onFetchUser();
    onFetchPhotos();
    onFetchArtists();
    onFetchOpeningHours();
    onFetchUsers();
    onFetchPromotions();
    onFetchInstagram();
  }, []);

  return (
    <Router>
      <Theme>
        <Suspense fallback={<Spinner />}>
          <Header />
          <Switch>
            <Route exact path="/" component={AsyncWelcomePage} />
            <Route path="/artists/:slug" component={AsyncArtistPage} />
            <Route path="/login" component={AsyncLoginPage} />
            <Route path="/terms" component={Terms} />
            <Route
              path="/admin"
              component={user._id ? AsyncAdminPage : AsyncLoginPage}
            />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </Theme>
    </Router>
  );
};

export default App;
