import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: ${props => (!props.nowidth_height ? "100%" : "auto")};
  height: ${props => (!props.nowidth_height ? "100%" : "auto")};
  transition-duration: 0.3s;
  transform: none;
  object-fit: ${props => props.contain ? "contain" : "cover"};
`;

const Image = props => {
  let image = null;

  if (props.src && props.alt) {
    image = (
      <StyledImg
        src={props.src}
        alt={props.alt}
        nowidth_height={props.nowidth_height}
        contain={props.contain}
      />
    );
  }

  return image;
};

export default Image;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};
