import React from "react";

import PropTypes from "prop-types";
import CardLink from "./CardLink/CardLink";

import styled from "styled-components";

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledTitle = styled.h2`
  width: fit-content;
  font-family: inherit;
  font-size: ${props => props.theme.fontSizes[props.fontSize]};
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  border-bottom: 3px solid ${props => props.theme.colors.tertiary.main};
  color: ${props =>
    props.titleColor === "primary"
      ? props.theme.colors.primary.main
      : props.titleColor === "secondary"
      ? props.theme.colors.secondary.main
      : props.titleColor === "tertiary"
      ? props.theme.colors.tertiary.main
      : "#fff"};
  margin-bottom: 20px;
  border-bottom: 3px solid
    ${props =>
      props.titleColor === "primary"
        ? props.theme.colors.primary.main
        : props.titleColor === "secondary"
        ? props.theme.colors.secondary.main
        : props.titleColor === "tertiary"
        ? props.theme.colors.tertiary.main
        : "#fff"};
`;

const StyledSubTitle = styled.h4`
  width: auto;
  font-size: ${props => props.theme.fontSizes[props.fontSize]};
  font-weight: ${props =>
    props.fontWeight === "large" ? props.theme.fontWeight.large : 600};
  color: ${props =>
    props.fontColor === "primary"
      ? props.theme.colors.primary.main
      : props.fontColor === "secondary"
      ? props.theme.colors.secondary.main
      : props.fontColor === "tertiary"
      ? props.theme.colors.tertiary.main
      : "#fff"};
  margin-bottom: 20px;
  line-height: ${props =>
    props.lineHeight === "large" ? props.theme.lineHeight.large : "normal"};
  @media (${props => props.theme.breakpoints.tableM}) {
    max-width: 170px;
    width: 100%;
    font-size: 34px;
    line-height: 44px;
  }
  @media (${props => props.theme.breakpoints.tableM}) {
    max-width: 170px;
    width: 100%;
    font-size: 24px;
    line-height: 34px;
  }
`;
const StyledText = styled.p`
  width: 100%;
  font-size: ${props => props.theme.fontSizes[props.fontSize]};
  font-weight: normal;
  color: ${props =>
    props.fontColor === "primary"
      ? props.theme.colors.primary.main
      : props.fontColor === "secondary"
      ? props.theme.colors.secondary.main
      : props.fontColor === "tertiary"
      ? props.theme.colors.tertiary.main
      : "#fff"};
  margin-bottom: 20px;
`;

const StyledLink = styled.div`
  width: 100%;
  text-align: right;
  padding: 10px 0;
  padding: 6px 0;
`;

const Card = props => {
  let title = null;
  let subtitle = null;
  let extraSubtitle = null;
  let text = null;
  let link = null;

  if (props.title) {
    title = (
      <StyledTitle titleColor={props.titleColor}>{props.title}</StyledTitle>
    );
  }
  if (props.subTitle) {
    subtitle = (
      <StyledSubTitle
        fontColor={props.fontColor}
        fontWeight={props.fontWeight}
        fontSize={props.fontSize}
      >
        {props.subTitle}
      </StyledSubTitle>
    );
  }
  if (props.extraSubtitle) {
    extraSubtitle = (
      <StyledSubTitle
        fontColor={props.fontColor}
        fontWeight={props.fontWeight}
        fontSize={props.fontSize}
      >
        {props.extraSubtitle}
      </StyledSubTitle>
    );
  }
  if (props.text) {
    text = <StyledText fontColor={props.fontColor}>{props.text}</StyledText>;
  }
  if (props.link) {
    link = (
      <StyledLink>
        <CardLink
          url={props.link.url}
          icons="false"
          rotateIcon="false"
          text={props.link.text}
          color={"red"}
        />
      </StyledLink>
    );
  }

  return (
    <StyledCard height={props.height} width={props.width} className="card">
      {title}
      {subtitle}
      {extraSubtitle}
      {text}
      {props.showLink && link}
    </StyledCard>
  );
};

export default Card;
Card.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
  showLink: PropTypes.bool.isRequired
};

Card.defaultProps = {
  title: "This is the title",
  subTitle: "This is the subTitle",
  text: "This is the text",
  fontColor: "secondary",
  fontSize: "medium",
  titleColor: "tertiary",
  showLink: true
};
