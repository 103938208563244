// Photos
export const FETCH_PHOTOS_INIT = "FETCH_PHOTOS_INIT";
export const FETCH_PHOTOS_SUCCESS = "FETCH_PHOTOS_SUCCESS";
export const FETCH_PHOTOS_FAIL = "FETCH_PHOTOS_FAIL";

export const ADD_PHOTO_INIT = "ADD_PHOTO_INIT";
export const ADD_PHOTO_SUCCESS = "ADD_PHOTO_SUCCESS";
export const ADD_PHOTO_FAIL = "ADD_PHOTO_FAIL";

export const DELETE_PHOTO_INIT = "DELETE_PHOTO_INIT";
export const DELETE_PHOTO_SUCCESS = "DELETE_PHOTO_SUCCESS";
export const DELETE_PHOTO_FAIL = "DELETE_PHOTO_FAIL";

// Artists
export const FETCH_ARTISTS_INIT = "FETCH_ARTISTS_INIT";
export const FETCH_ARTISTS_SUCCESS = "FETCH_ARTISTS_SUCCESS";
export const FETCH_ARTISTS_FAIL = "FETCH_ARTISTS_FAIL";

export const ADD_ARTIST_INIT = "ADD_ARTIST_INIT";
export const ADD_ARTIST_SUCCESS = "ADD_ARTIST_SUCCESS";
export const ADD_ARTIST_FAIL = "ADD_ARTIST_FAIL";

export const UPDATE_ARTIST_INIT = "UPDATE_ARTIST_INIT";
export const UPDATE_ARTIST_SUCCESS = "UPDATE_ARTIST_SUCCESS";
export const UPDATE_ARTIST_FAIL = "UPDATE_ARTIST_FAIL";

export const DELETE_ARTIST_INIT = "DELETE_ARTIST_INIT";
export const DELETE_ARTIST_SUCCESS = "DELETE_ARTIST_SUCCESS";
export const DELETE_ARTIST_FAIL = "DELETE_ARTIST_FAIL";

// Users
export const FETCH_USERS_INIT = "FETCH_USERS_INIT";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";

export const FETCH_USER_INIT = "FETCH_USER_INIT";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const ADD_USER_INIT = "ADD_USER_INIT";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const UPDATE_USER_INIT = "UPDATE_USER_INIT";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER_INIT = "DELETE_USER_INIT";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

// Campaigns
export const FETCH_CAMPAIGNS_INIT = "FETCH_CAMPAIGNS_INIT";
export const FETCH_CAMPAIGNS_SUCCESS = "FETCH_CAMPAIGNS_SUCCESS";
export const FETCH_CAMPAIGNS_FAIL = "FETCH_CAMPAIGNS_FAIL";

export const ADD_CAMPAIGN_INIT = "ADD_CAMPAIGN_INIT";
export const ADD_CAMPAIGN_SUCCESS = "ADD_CAMPAIGN_SUCCESS";
export const ADD_CAMPAIGN_FAIL = "ADD_CAMPAIGN_FAIL";

export const UPDATE_CAMPAIGN_INIT = "UPDATE_CAMPAIGN_INIT";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL";

export const DELETE_CAMPAIGN_INIT = "DELETE_CAMPAIGN_INIT";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAIL = "DELETE_CAMPAIGN_FAIL";

// Opening hours
export const FETCH_OPENINGHOURS_INIT = "FETCH_OPENINGHOURS_INIT";
export const FETCH_OPENINGHOURS_SUCCESS = "FETCH_OPENINGHOURS_SUCCESS";
export const FETCH_OPENINGHOURS_FAIL = "FETCH_OPENINGHOURS_FAIL";

export const ADD_OPENINGHOUR_INIT = "ADD_OPENINGHOUR_INIT";
export const ADD_OPENINGHOUR_SUCCESS = "ADD_OPENINGHOUR_SUCCESS";
export const ADD_OPENINGHOUR_FAIL = "ADD_OPENINGHOUR_FAIL";

export const UPDATE_OPENINGHOUR_INIT = "UPDATE_OPENINGHOUR_INIT";
export const UPDATE_OPENINGHOUR_SUCCESS = "UPDATE_OPENINGHOUR_SUCCESS";
export const UPDATE_OPENINGHOUR_FAIL = "UPDATE_OPENINGHOUR_FAIL";

export const DELETE_OPENINGHOUR_INIT = "DELETE_OPENINGHOUR_INIT";
export const DELETE_OPENINGHOUR_SUCCESS = "DELETE_OPENINGHOUR_SUCCESS";
export const DELETE_OPENINGHOUR_FAIL = "DELETE_OPENINGHOUR_FAIL";

// Email
export const SEND_EMAIL_INIT = "SEND_EMAIL_INIT";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL";

// Verify Token ReCatcha
export const VERIFY_TOKEN_INIT = "VERIFY_TOKEN_INIT";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const VERIFY_TOKEN_FAIL = "VERIFY_TOKEN_FAIL";

// Instagram
export const FETCH_INSTAGRAM_INIT = "FETCH_INSTAGRAM_INIT";
export const FETCH_INSTAGRAM_SUCCESS = "FETCH_INSTAGRAM_SUCCESS";
export const FETCH_INSTAGRAM_FAIL = "FETCH_INSTAGRAM_FAIL";

// Modal
export const FETCH_PROMOTIONS_INIT = "FETCH_PROMOTIONS_INIT";
export const FETCH_PROMOTIONS_SUCCESS = "FETCH_PROMOTIONS_SUCCESS";
export const FETCH_PROMOTIONS_FAIL = "FETCH_PROMOTIONS_FAIL";

export const ADD_PROMOTION_INIT = "ADD_PROMOTION_INIT";
export const ADD_PROMOTION_SUCCESS = "ADD_PROMOTION_SUCCESS";
export const ADD_PROMOTION_FAIL = "ADD_PROMOTION_FAIL";

export const UPDATE_PROMOTION_INIT = "UPDATE_PROMOTION_INIT";
export const UPDATE_PROMOTION_SUCCESS = "UPDATE_PROMOTION_SUCCESS";
export const UPDATE_PROMOTION_FAIL = "UPDATE_PROMOTION_FAIL";

export const DELETE_PROMOTION_INIT = "DELETE_PROMOTION_INIT";
export const DELETE_PROMOTION_SUCCESS = "DELETE_PROMOTION_SUCCESS";
export const DELETE_PROMOTION_FAIL = "DELETE_PROMOTION_FAIL";
