import * as actions from "../actionTypes";
import axios from "axios";

export const fetchArtistsInit = () => {
  return {
    type: actions.FETCH_ARTISTS_INIT,
  };
};

const fetchArtistsSuccess = (artists, ids) => {
  return {
    type: actions.FETCH_ARTISTS_SUCCESS,
    artists,
    ids,
  };
};

const fetchArtistsFail = (error) => {
  return {
    type: actions.FETCH_ARTISTS_FAIL,
    error,
  };
};
export const fetchArtists = () => (dispatch) => {
  dispatch(fetchArtistsInit());
  axios
    .get(`/api/artists`)
    .then(({ data }) => {
      const fetchedArtists = {};
      const fetchedArtistIds = [];
      data.map((artist) => {
        fetchedArtists[artist._id] = { ...artist };
        fetchedArtistIds.push(artist._id);
        return artist;
      });

      dispatch(fetchArtistsSuccess(fetchedArtists, fetchedArtistIds));
    })
    .catch((error) => {
      dispatch(fetchArtistsFail(error));
    });
};

export const addArtistInit = () => {
  return {
    type: actions.ADD_ARTIST_INIT,
  };
};

const addArtistSuccess = (artist, id) => {
  return {
    type: actions.ADD_ARTIST_SUCCESS,
    artist,
    id,
  };
};

const addArtistFail = (error) => {
  return {
    type: actions.ADD_ARTIST_FAIL,
    error,
  };
};

export const submitArtistNew = (values) => {
  const fd = new FormData();
  Object.entries(values).map(([key, value]) => {
    if (key !== "photo") {
      fd.append(key, value);
    }
    return key;
  });
  fd.append("photo", values.photo.file);

  return async (dispatch) => {
    dispatch(addArtistInit());

    try {
      let { data: artist } = await axios.post("/api/artists", fd);
      const fetchedArtist = {};
      let artistId = artist._id;

      artist = Object.keys(artist).map((key) => {
        if (key !== "__v") {
          fetchedArtist[key] = artist[key];
        }
        return key;
      });
      dispatch(addArtistSuccess(fetchedArtist, artistId));
    } catch (error) {
      dispatch(addArtistFail(error));
    }
  };
};

export const updateArtistInit = () => {
  return {
    type: actions.UPDATE_ARTIST_INIT,
  };
};

const updateArtistSuccess = (artist, id) => {
  return {
    type: actions.UPDATE_ARTIST_SUCCESS,
    artist,
    id,
  };
};

const updateArtistFail = (error) => {
  return {
    type: actions.UPDATE_ARTIST_FAIL,
    error,
  };
};

export const submitArtistUpdate = (values) => {
  const fd = new FormData();

  Object.entries(values).map(([key, value]) => {
    if (key !== "photo") {
      fd.append(key, value);
    }
    return key;
  });

  fd.append("photo", values.photo.file);

  return async (dispatch) => {
    dispatch(updateArtistInit());

    try {
      let { data: artist } = await axios.put("/api/artists", fd);

      const fetchedArtist = {};
      let artistId = artist._id;

      artist = Object.keys(artist).map((key) => {
        if (key !== "__v") {
          fetchedArtist[key] = artist[key];
        }
        return key;
      });

      dispatch(updateArtistSuccess(fetchedArtist, artistId));
    } catch (error) {
      dispatch(updateArtistFail(error));
    }
  };
};
export const deleteArtistInit = () => {
  return {
    type: actions.DELETE_ARTIST_INIT,
  };
};

const deleteArtistSuccess = (id) => {
  return {
    type: actions.DELETE_ARTIST_SUCCESS,
    id,
  };
};

const deleteArtistFail = (error) => {
  return {
    type: actions.DELETE_ARTIST_FAIL,
    error,
  };
};

export const deleteArtist = (artist) => {
  return (dispatch) => {
    dispatch(deleteArtistInit());
    axios
      .delete("/api/artists", {
        data: { _id: artist._id, path: artist.path },
      })
      .then(({ data: artist }) => {
        let artistId = artist._id;
        dispatch(deleteArtistSuccess(artistId));
      })
      .catch((error) => {
        dispatch(deleteArtistFail(error));
      });
  };
};
