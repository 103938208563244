import React, { useState, useEffect } from "react";
import "./Header.css";
import Toolbar from "./Toolbar/Toolbar";
import ReactGA from "react-ga";

const Header = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  const handleDrawerToggleClicked = () => {
    setShowDrawer(!showDrawer);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <header className="header">
      <Toolbar
        open={showDrawer}
        drawerToggleClicked={handleDrawerToggleClicked}
      />
    </header>
  );
};

export default Header;
