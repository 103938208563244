import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  error: false,
  promotions: {},
  ids: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROMOTIONS_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.FETCH_PROMOTIONS_SUCCESS:
      return {
        ...state,
        promotions: { ...state.promotions, ...action.promotions },
        ids: action.ids,
        loading: false,
        error: false
      };
    case actionTypes.FETCH_PROMOTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.ADD_PROMOTION_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_PROMOTION_SUCCESS:
      return {
        ...state,
        promotions: { [action.id]: action.promotion, ...state.promotions },
        ids: [action.id, ...state.ids],
        loading: false,
        error: false
      };
    case actionTypes.ADD_PROMOTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.UPDATE_PROMOTION_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_PROMOTION_SUCCESS:
      return {
        ...state,
        promotions: {
          ...state.promotions,
          [action.id]: { ...state.promotions[action.id], ...action.promotion }
        },
        ids: [...state.ids],
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_PROMOTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.DELETE_PROMOTION_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_PROMOTION_SUCCESS:
      let updatedPromotions = { ...state.promotions };
      delete updatedPromotions[action.id];
      let updatedIds = state.ids.filter(id => id !== action.id);
      return {
        ...state,
        promotions: { ...updatedPromotions },
        ids: updatedIds,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_PROMOTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};
export default reducer;
