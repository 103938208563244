import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition-duration: 0.5s;
`;

const Backdrop = props => {
  let backdrop = null;
  if (props.open) {
    backdrop = <StyledBackdrop onClick={props.clicked} />;
  }
  return backdrop;
};

export default Backdrop;

Backdrop.propTypes = {
  open: PropTypes.bool.isRequired,
  clicked: PropTypes.func.isRequired
};
