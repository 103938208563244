import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  error: false,
  artists: {},
  ids: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ARTISTS_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.FETCH_ARTISTS_SUCCESS:
      return {
        ...state,
        artists: { ...state.artists, ...action.artists },
        ids: action.ids,
        loading: false,
        error: false
      };
    case actionTypes.FETCH_ARTISTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.ADD_ARTIST_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_ARTIST_SUCCESS:
      return {
        ...state,
        artists: { [action.id]: action.artist, ...state.artists },
        ids: [action.id, ...state.ids],
        loading: false,
        error: false
      };
    case actionTypes.ADD_ARTIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.UPDATE_ARTIST_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_ARTIST_SUCCESS:
      return {
        ...state,
        artists: {
          ...state.artists,
          [action.id]: { ...state.artists[action.id], ...action.artist }
        },
        ids: [...state.ids],
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_ARTIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.DELETE_ARTIST_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_ARTIST_SUCCESS:
      let updatedArtists = { ...state.artists };
      delete updatedArtists[action.id];
      let updatedIds = state.ids.filter(id => id !== action.id);
      return {
        ...state,
        artists: { ...updatedArtists },
        ids: updatedIds,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_ARTIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};
export default reducer;
