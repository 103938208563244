import React from "react";
import styled from "styled-components";

const StyledColumn = styled.div`
  width: ${props =>
    props.maxWidth === "maxWidth" ? props.theme.maxWidth.mobileS : "33.333%"};
  float: left;
  position: relative;
  padding: 0 20px 20px 20px;
  display: ${props =>
    props.contactVideo === "contactVideo" ? "flex" : "initial"};

  @media (${props => props.theme.breakpoints.tableM}) {
    width: 50%;
  }
  @media (${props => props.theme.breakpoints.mobileL}) {
    width: 100%;
  }
  @media (${props => props.theme.breakpoints.maxMobile}) {
    display: ${props => (props.mobileDisplay === "none" ? "none" : "initial")};
  }
`;

const Column = props => (
  <StyledColumn
    mobileDisplay={props.mobileDisplay}
    maxWidth={props.maxWidth}
    contactVideo={props.contactVideo}
  >
    {props.children}
  </StyledColumn>
);

export default Column;
