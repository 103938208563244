import React from "react";
import SocialMediaItem from "./SocialMediaItem/SocialMediaItem";
import PropTypes from "prop-types";
import styled from "styled-components";
let {
  theme: { socialLinks },
} = require("../../../hoc/theme");

export const StyledBar = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: center;
  min-height: 40px;
  flex-wrap: wrap;

  @media only screen and (max-width: 666px) {
    display: ${(props) => (props.tabletDisplay === "none" ? "none" : "flex")};
    margin: 20px 0;
  }
`;

export const StyledText = styled.p`
  margin-right: ${(props) => (props.direction === "row" ? "20px" : "0px")};
  margin-bottom: ${(props) => (props.direction === "column" ? "20px" : "0px")};
  color: ${(props) =>
    props.color === "primary"
      ? props.theme.colors.primary.main
      : props.color === "secondary"
      ? props.theme.colors.secondary.main
      : props.color === "tertiary"
      ? props.theme.colors.tertiary.main
      : props.theme.colors.secondary.main};
  @media only screen and (max-width: 350px) {
    font-size: 13px;
  }
`;

export const StyledItems = styled.div`
  display: flex;
  flex-direction: row;
  // max-width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  transition-duration: 0.3s;
  justify-content: space-around;
  align-items: center;
`;

const SocialMediaBar = (props) => {
  let socialLinks = null;
  if (props.socialLinks && props.socialLinks.length) {
    socialLinks = props.socialLinks.map((link) => (
      <SocialMediaItem
        href={link.href}
        key={link.name}
        src={link.src}
        rotateIcon={props.rotateIcon}
        iconColor={props.iconColor}
        color={props.color}
        socialLinks={props.socialLinks}
      />
    ));
  }

  return (
    <StyledBar
      direction={props.direction}
      className="SocialMediaBar"
      tabletDisplay={props.tabletDisplay}
    >
      <StyledText color={props.color} direction={props.direction}>
        Folgen Sie uns
      </StyledText>
      <StyledItems>{socialLinks}</StyledItems>
    </StyledBar>
  );
};

export default SocialMediaBar;

SocialMediaBar.propTypes = {
  direction: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
};

SocialMediaBar.defaultProps = {
  direction: "row",
  color: "primary",
  socialLinks,
};
