import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/de";

const StyledOpeningHours = styled.div`
  p {
    color: ${(props) => props.theme.colors.primary.main};
    padding: 10px 0;
  }
  & .today {
    font-weight: bold;
    color: ${(props) => props.theme.colors.tertiary.main};
  }
`;

const StyledH2 = styled.h2`
  width: fit-content;
  font-family: inherit;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  border-bottom: 3px solid ${(props) => props.theme.colors.tertiary.main};
  color: ${(props) => props.theme.colors.tertiary.main};
  margin-bottom: 20px;
`;

const OpeningHourItems = (props) => {
  const today = moment().locale("de").format("dddd");

  let openingHours = null;
  if (props.openingHours && Object.values(props.openingHours).length) {
    openingHours = Object.values(props.openingHours).map((open) => {
      return (
        <p key={open._id} className={open.day === today ? "today" : ""}>
          {open.day} - {open.time}
        </p>
      );
    });
  }
  return (
    <StyledOpeningHours>
      <StyledH2>Öffnungszeiten</StyledH2>
      {openingHours}
    </StyledOpeningHours>
  );
};

const mapStateToProps = (state) => {
  return {
    openingHours: state.openingHourReducer.openingHours,
  };
};

export default connect(mapStateToProps)(withRouter(OpeningHourItems));
