import React from "react";
import styled from "styled-components";

const StyledRow = styled.div`
  width: 100%;
  padding: 60px 0;
  // @media(${props => props.theme.breakpoints.mobileS}){
  //   height: 550px;
  // }
`;


const Row = props => (
  <StyledRow>
    {props.children}
  </StyledRow>
);

export default Row;

