import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledBackground = styled.div`
  width: 100%;
  height: inherit;
  
  background-color: ${props =>
    props.bgcolor ? props.theme.colors[props.bgcolor].main : "transparent"};
  background-image: ${props =>
    props.bgimage ? `url(${props.theme.bgimage[props.bgimage]})` : "none"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  @media only screen and (max-width: 768px) {
    height: 100%;
    background-position: 20%;
    background-attachment: initial;
  }
`;
const Background = props => {
  return (
    <StyledBackground
      bgcolor={props.bgcolor}
      bgimage={props.bgimage}
      className="background"
    >
      {props.children}
    </StyledBackground>
  );
};

export default Background;

Background.propTypes = {
  bgcolor: PropTypes.string,
  bgimage: PropTypes.string
};
