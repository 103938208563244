import React from "react";
import { Link } from "react-router-dom";
import NavigationItem from "../Header/Toolbar/NavigationItems/NavigationItem/NavigationItem";
import styled from "styled-components";
import ReactGA from "react-ga";

const StyledPolicy = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  font-size: 12px;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
  & a {
    color: #fff;
    padding: 0 10px;

    &:hover {
      text-shadow: 0px 0px 1px #fff;
    }
  }

  & span {
    display: flex;
  }

  & span a:last-child {
    display: flex;
  }
  & p {
    color: #fff;
    font-size: 12px;
  }

  @media only screen and (max-width: 767px) {
    & span a:last-child {
      display: none;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 900px) {
    & span a:last-child {
      display: none;
    }
  }
`;

const Policy = () => (
  <StyledPolicy>
    <p>
      © {new Date().getFullYear()} The Inked Clown Tattoo. Alle Rechte
      vorbehalten.
    </p>
    <span>
      <NavigationItem path="/terms" to="terms">
        AGB, Impressum und Datenschutz
      </NavigationItem>
      <Link
        to="/login"
        onClick={() => {
          ReactGA.event({
            category: "Login",
            action: "Login pressed",
          });
        }}
      >
        Anmeldung
      </Link>
    </span>
  </StyledPolicy>
);

export default Policy;
