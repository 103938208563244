import React, { useState, useEffect } from "react";
import "../Header.css";
import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../../../hoc/theme";

import Logo from "./Logo/Logo";
import NavigationItems from "./NavigationItems/NavigationItems";
import Drawer from "./Drawer/Drawer";
import DrawerToggle from "./DrawerToggle/DrawerToggle";

const StyledToolbar = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
  padding: 15px 20px;
  background-color: none;
  z-index: 1000;
  transition: 0.5s;

  &.dark li:hover ul {
    margin-top: 18px;
  }

  &.dark .StyledLogo > img {
    width: 12%;
  }

  &.dark .StyledNav .Logo {
    max-width: 88px;
  }

  &.dark {
    background-color: ${props => props.theme.colors.lightblack};
    padding: 0;
    z-index: 1;
    justify-content: center;
    transition: 0.5s;
  }

  @media only screen and (${theme.breakpoints.midMobile}) {
    &.dark .StyledLogo > img {
      width: 25%;
    }
  }
  @media only screen and (${theme.breakpoints.midTablet}) {
    justify-content: space-between;
    transition: 1s;
  
    &.dark {
      justify-content: space-between;
      padding: 10px 20px;
      transition: 1s;
    }
  }
  @media only screen and (${theme.breakpoints.mobileLandscapeHeader}) {
    padding: 5px 20px;
    transition: 0.5s;

    &.dark {
      padding: 15px 20px;
      transition: 0.5s;
    }
  }
`;

const StyledLogo = styled.div`
  display: none;

  @media (${props => props.theme.breakpoints.midTablet}) {
    display: block;
    width: auto;
    transition: 0.5s;
  }
`;

const StyledNav = styled.nav`
  max-width: 1024px;
  width: 100%;
  height: 100%;
  display: block;

  @media (${props => props.theme.breakpoints.midTablet}) {
    display: none;
  }
`;

const Toolbar = props => {
  const [scrollPos, setScrollPos] = useState(window.pageYOffset);
  const [transparent, setTransparent] = useState(true);
  const headerRef = React.createRef();
  const [links, setLinks] = useState(null);
  const navRef = React.createRef();

  useEffect(() => {
    window.onscroll = () => setScrollPos(window.pageYOffset);
    setLinks(navRef.current.getElementsByTagName("a"));
  }, [navRef]);

  useEffect(() => {
    if (scrollPos > 90) {
      setTransparent(true);
      headerRef.current.classList.add("dark");
    } else {
      setTransparent(false);
      headerRef.current.classList.remove("dark");
    }
  }, [scrollPos, headerRef]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (links) {
        [...links].map(a => {
          if (a.nextSibling) {
            a.nextSibling.classList.add("active");
            if (a.classList.contains("active-ghost")) {
            } else {
              a.nextSibling.classList.remove("active");
            }
          }
          return a;
        });
      }
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, [scrollPos, links]);

  return (
    <>
      <StyledToolbar ref={headerRef} name="StyledToolbar">
        <StyledLogo name="StyledLogo" className="StyledLogo">
          <a href="/welcome">
            <Logo resize="resizeLogo" />
          </a>
        </StyledLogo>
        <DrawerToggle
          name="DrawerToggle"
          open={props.open}
          clicked={props.drawerToggleClicked}
        />
        <StyledNav ref={navRef} name="StyledNav" className="StyledNav">
          <NavigationItems transparent={transparent} name="NavigationItems" />
        </StyledNav>
        <Drawer
          className="Drawer"
          name="Drawer"
          open={props.open}
          drawerToggleClicked={props.drawerToggleClicked}
        />
      </StyledToolbar>
    </>
  );
};
export default Toolbar;

Toolbar.propTypes = {
  drawerToggleClicked: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
