import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  error: false,
  instagrams: {},
  ids: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INSTAGRAM_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.FETCH_INSTAGRAM_SUCCESS:
      return {
        ...state,
        instagrams: { ...state.instagrams, ...action.instagrams },
        ids: action.ids,
        loading: false,
        error: false
      };
    case actionTypes.FETCH_INSTAGRAM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};
export default reducer;
