import React from "react";
import { Element } from "react-scroll";

import ContactItems from "./ContactItems/ContactItems";
import OpeningHourItems from "./OpeningHourItems/OpeningHourItems";
import ContactForm from "./ContactForm/ContactForm";

import Column from "../UI/Column/Column";
import Row from "../UI/Row/Row";
import Section from "../UI/Section/Section";
import Card from "../UI/Card/Card";
import SocialMediaBar from "../UI/SocialMediaBar/SocialMediaBar.js";
import Policy from "../Policy/Policy";

const Contact = (props) => {
  return (
    <Element id="contact" name="contact">
      <Section bgcolor="secondary">
        <Row>
          <Element
            id="contact"
            name="contact"
            style={{ margin: "0 auto", display: "flex", flexWrap: "wrap" }}
          >
            <Column>
              <Card
                title="Über uns"
                subTitle={``}
                text="Das am 1. November 2020 gegründete Tattoostudio Inked Clown hat sich bei Google schnell als das bestbewertete Studio der Stadt etabliert. Dieser Erfolg beruht auf der Professionalität, Kreativität und dem persönlichen Umgang des Teams mit jedem Kunden.
                      Unsere erfahrenen Tätowierer erwecken Ihre Ideen zum Leben und helfen Ihnen, das perfekte Design zu finden. Neben Tattoos bieten wir auch Piercings, Maniküre und Pediküre an und sorgen so für ein umfassendes Schönheitserlebnis"
                fontColor="primary"
                titleColor="tertiary"
                fontSize="smallL"
                showLink={false}
              />
            </Column>
            <Column>
              <ContactItems />
              <SocialMediaBar
                socialLinks={props.socialLinks}
                rotateIcon="rotate"
                iconColor="neonred"
              />
            </Column>
            <Column>
              <OpeningHourItems />
            </Column>
            <Column maxWidth="maxWidth">
              <ContactForm loading={props.loading} send={props.send} />
            </Column>
            <Column
              mobileDisplay="none"
              contactVideo="contactVideo"
              maxWidth="maxWidth"
            >
              <video
                className="contactVideo"
                controlsList="nodownload"
                playsInline
                autoPlay
                muted
                loop
              >
                <source src="/video/TD_ART_FOOTER.MP4" type="video/mp4" />
              </video>
            </Column>
          </Element>
        </Row>
        <Policy />
      </Section>
    </Element>
  );
};

export default Contact;
