import { css } from "styled-components";

export default css`
font-size: inherit;
text-align: center;
color: ${props => props.theme.colors.primary.main};
border-bottom: 3px solid transparent;
padding: 10px 0;
cursor: pointer;

&:not(.active):hover {
  color: ${props => props.theme.colors.primary.main};
}
&.active {
  border-bottom: 3px solid ${props => props.theme.colors.tertiary.main};
  transition-duration: 0.3s;
}

@media (${props => props.theme.breakpoints.tablet}) {
  color: ${props => props.theme.colors.primary.main};
  width: 100%;
  border-bottom: none;

  &.active {
    border-bottom: 3px solid ${props => props.theme.colors.secondary.main};
    transition-duration: 0.3s;
  }
`;
