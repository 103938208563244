import * as actions from "../actionTypes";
import axios from "axios";

export const fetchUserInit = () => {
  return {
    type: actions.FETCH_USER_INIT
  };
};

const fetchUserSuccess = (user, id) => {
  return {
    type: actions.FETCH_USER_SUCCESS,
    user,
    id
  };
};

const fetchUserFail = error => {
  return {
    type: actions.FETCH_USER_FAIL,
    error
  };
};

export const fetchUser = () => {
  return async dispatch => {
    dispatch(fetchUserInit());
    const { data: user } = await axios.get("/api/current_user");
    try {
      dispatch(fetchUserSuccess(user));
    } catch (error) {
      dispatch(fetchUserFail(error));
    }
  };
};

export const fetchUsersInit = () => {
  return {
    type: actions.FETCH_USERS_INIT
  };
};

const fetchUsersSuccess = (users, ids) => {
  return {
    type: actions.FETCH_USERS_SUCCESS,
    users,
    ids
  };
};

const fetchUsersFail = error => {
  return {
    type: actions.FETCH_USERS_FAIL,
    error
  };
};
export const fetchUsers = () => dispatch => {
  dispatch(fetchUsersInit());
  axios
    .get(`/api/users`)
    .then(({ data }) => {
      const fetchedUsers = {};
      const fetchedUserIds = [];
      data.map(user => {
        fetchedUsers[user._id] = { ...user };
        fetchedUserIds.push(user._id);
        return user;
      });

      dispatch(fetchUsersSuccess(fetchedUsers, fetchedUserIds));
    })
    .catch(error => {
      dispatch(fetchUsersFail(error));
    });
};

export const addUserInit = () => {
  return {
    type: actions.ADD_USER_INIT
  };
};

const addUserSuccess = (user, id) => {
  return {
    type: actions.ADD_USER_SUCCESS,
    user,
    id
  };
};

const addUserFail = error => {
  return {
    type: actions.ADD_USER_FAIL,
    error
  };
};

export const submitUserNew = values => {
  return async dispatch => {
    dispatch(addUserInit());
    try {
      let { data: user } = await axios.post("/api/users", values);
      const fetchedUser = {};
      let userId = user._id;
      user = Object.keys(user).map(key => {
        if (key !== "__v") {
          fetchedUser[key] = user[key];
        }
        return key;
      });
      dispatch(addUserSuccess(fetchedUser, userId));
    } catch (error) {
      dispatch(addUserFail(error));
    }
  };
};

export const updateUserInit = () => {
  return {
    type: actions.UPDATE_USER_INIT
  };
};

const updateUserSuccess = (user, id) => {
  return {
    type: actions.UPDATE_USER_SUCCESS,
    user,
    id
  };
};

const updateUserFail = error => {
  return {
    type: actions.UPDATE_USER_FAIL,
    error
  };
};

export const submitUserUpdate = values => {
  return async dispatch => {
    dispatch(updateUserInit());
    try {
      let { data: user } = await axios.put("/api/users", values);
      const fetchedUser = {};
      let userId = values._id;
      user = Object.keys(user).map(key => {
        if (key !== "__v") {
          fetchedUser[key] = user[key];
        }
        return key;
      });
      dispatch(updateUserSuccess(fetchedUser, userId));
    } catch (error) {
      dispatch(updateUserFail(error));
    }
  };
};
export const deleteUserInit = () => {
  return {
    type: actions.DELETE_USER_INIT
  };
};

const deleteUserSuccess = id => {
  return {
    type: actions.DELETE_USER_SUCCESS,
    id
  };
};

const deleteUserFail = error => {
  return {
    type: actions.DELETE_USER_FAIL,
    error
  };
};

export const deleteUser = ({ _id }) => {
  return async dispatch => {
    dispatch(deleteUserInit());
    try {
      let { data: user } = await axios.delete("/api/users", {
        data: { _id }
      });
      let userId = user._id;
      dispatch(deleteUserSuccess(userId));
    } catch (error) {
      dispatch(deleteUserFail(error));
    }
  };
};
