import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  error: false,
  user: {},
  users: {},
  ids: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USERS_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: { ...state.users, ...action.users },
        ids: action.ids,
        loading: false,
        error: false
      };
    case actionTypes.FETCH_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.FETCH_USER_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.user },
        id: action.id,
        loading: false,
        error: false
      };
    case actionTypes.FETCH_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.ADD_USER_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_USER_SUCCESS:
      return {
        ...state,
        users: { [action.id]: action.user, ...state.users },
        ids: [action.id, ...state.ids],
        loading: false,
        error: false
      };
    case actionTypes.ADD_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.UPDATE_USER_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.id]: { ...state.users[action.id], ...action.user }
        },
        ids: [...state.ids],
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.DELETE_USER_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_USER_SUCCESS:
      let updatedUsers = { ...state.users };
      delete updatedUsers[action.id];
      let updatedIds = state.ids.filter(id => id !== action.id);
      return {
        ...state,
        users: { ...updatedUsers },
        ids: updatedIds,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};
export default reducer;
