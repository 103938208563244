import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  error: false,
  campaigns: {},
  ids: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGNS_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: { ...state.campaigns, ...action.campaigns },
        ids: action.ids,
        loading: false,
        error: false
      };
    case actionTypes.FETCH_CAMPAIGNS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.ADD_CAMPAIGN_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: { [action.id]: action.campaign, ...state.campaigns },
        ids: [action.id, ...state.ids],
        loading: false,
        error: false
      };
    case actionTypes.ADD_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.UPDATE_CAMPAIGN_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [action.id]: { ...state.campaigns[action.id], ...action.campaign }
        },
        ids: [...state.ids],
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.DELETE_CAMPAIGN_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_CAMPAIGN_SUCCESS:
      let updatedCampaigns = { ...state.campaigns };
      delete updatedCampaigns[action.id];
      let updatedIds = state.ids.filter(id => id !== action.id);
      return {
        ...state,
        campaigns: { ...updatedCampaigns },
        ids: updatedIds,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};
export default reducer;
