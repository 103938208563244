import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledIcon = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: ${props => (!props.nowidth_height ? "auto" : "inherit")};
  height: ${props => (!props.nowidth_height ? "auto" : "inherit")};
  object-fit: cover;
  transition-duration: 0.3s;
  transform: none;
  filter: ${props =>
    props.iconColor === "filter"
      ? props.theme.iconColors.black
      : props.iconColor === "white"
      ? props.theme.iconColors.white
      : "none"};
  &:hover {
    filter: ${props =>
      props.iconColor === "filter"
        ? props.theme.iconColors.neonred
        : props.iconColor === "neonred"
        ? props.theme.iconColors.white
        : "none"};
    transform: ${props =>
      props.rotateIcon === "rotate" ? "rotate(360deg)" : "none"};
  }
`;

const Icon = props => {
  let icon = null;
  if (props.src && props.alt) {
    icon = (
      <StyledIcon
        alt={props.alt}
        iconColor={props.iconColor}
        nowidthImg={props.nowidthImg}
        rotateIcon={props.rotateIcon}
        src={props.src}
      />
    );
  }
  return icon;
};

export default Icon;

Icon.propTypes = {
  alt: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  src: PropTypes.string.isRequired,
  rotateIcon: PropTypes.string
};
