import React from "react";
import TopBackground from "./TopBackground/TopBackground";
import Container from "../Container/Container";

import classes from "./TopTitle.module.css";
const TopTitle = props => (
  <TopBackground>
    <Container>
      <h1 className={classes.TopTitle}>{props.children}</h1>
    </Container>
  </TopBackground>
);

export default TopTitle;
