import React from "react";
import { Element } from "react-scroll";
import { ParallaxProvider } from "react-scroll-parallax";
import styled from "styled-components";

import TopTitle from "../../components/UI/TopTitle/TopTitle";
import Section from "../../components/UI/Section/Section";
import Contact from "../../components/Contact/Contact";
import Map from "../../components/Map/Map";

let {
  theme: { socialLinks }
} = require("../../hoc/theme");

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  padding: 80px 1.5rem;
`;

const StyledAddress = styled.span`
  width: 100%;
  height: 100%;
  display: block;
  padding: 80px 0;
`;

const Terms = props => {
  return (
    <Element id="terms" name="terms">
      <ParallaxProvider>
        <TopTitle>Terms and Privacy</TopTitle>
        <Section bgcolor="greyBg">
          <StyledContainer>
            <h2>Impressum und Datenschutzerklärung</h2>
            <p>Angaben gemäß § 5 TMG:</p>
            <StyledAddress>
            The Inked Clown Tattoo Studio <br />
            Uhlstraße 91 <br />
            50321 Brühl <br />
            Telefon: 015753638272 <br />
            E-Mail: titarev-denis@mail.ru <br />
              <br />
            Vertreten durch Inhaber Titarev
          </StyledAddress>
            <div>
              <h3>Haftungsausschluss</h3>
              <p>
                <br />
                <span style={{ fontWeight: "bold" }}>Haftung für Inhalte</span>
                <br />
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
              Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
              können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
              sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
              nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
              sind wir als Diensteanbieter jedoch nicht verpflichtet,
              übermittelte oder gespeicherte fremde Informationen zu überwachen
              oder nach Umständen zu forschen, die auf eine rechtswidrige
              Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
              der Nutzung von Informationen nach den allgemeinen Gesetzen
              bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
              erst ab dem Zeitpunkt der Kenntnis einer konkreten
              Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
              Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
              <p>
                <br />
                <span style={{ fontWeight: "bold" }}>Urheberrecht</span>
                <br />
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </p>
            </div>
          </StyledContainer>
        </Section>
        <Contact socialLinks={socialLinks} />
        <Map />
      </ParallaxProvider>
    </Element>
  );
};

export default Terms;
