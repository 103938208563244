import React from "react";
import styled from "styled-components";

const StyledLogo = styled.img`
display: block;
object-fit: cover;
max-width: ${props => props.resize ? "66px" : props.theme.resizes.resizeLogo};
transition: 0.5s;
`;


const Logo = props => {
  return (
    <StyledLogo className="Logo" name="StyledLogo" resize={props.resize} src={"/logo/tdart-logo-new.png"} alt={"Logo"} />
  );
};

export default Logo;
