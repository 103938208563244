export { fetchPhotos, submitPhotoNew, deletePhoto } from "./photo";
export {
  fetchArtists,
  submitArtistNew,
  submitArtistUpdate,
  deleteArtist
} from "./artist";
export {
  fetchUser,
  fetchUsers,
  submitUserNew,
  submitUserUpdate,
  deleteUser
} from "./user";
export {
  fetchCampaigns,
  submitCampaignNew,
  submitCampaignUpdate,
  deleteCampaign
} from "./campaign";
export {
  fetchPromotions,
  submitPromotionNew,
  submitPromotionUpdate,
  deletePromotion
} from "./promotion";
export {
  fetchOpeningHours,
  submitOpeningHourNew,
  submitOpeningHourUpdate,
  deleteOpeningHour
} from "./openingHour";
export { submitEmailNew } from "./email";
export {  verifyToken } from "./verifyToken";
export { fetchInstagram } from "./instagram";
