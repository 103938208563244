import React from "react";
import NavigationItem from "./NavigationItem/NavigationItem";
import { Link as RouterLink } from "react-router-dom";
import Scroll from "react-scroll";
import Logo from "../Logo/Logo";
import styled from "styled-components";
import styledLinks from "./styledLinks.js";
import { connect } from "react-redux";

const StyledNavigation = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  & li a:hover {
    border-bottom: 3px solid red;
    @media (${(props) => props.theme.breakpoints.tablet}) {
      border-bottom: 3px solid #fff;
    }
  }
  & li:hover ul {
    position: relative;
    margin-top: 0;
    border-bottom: 3px solid #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    visibility: visible;
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
    transition: 0.3s;
    opacity: 1;
  }

  @media (${(props) => props.theme.breakpoints.midTablet}) {
    display: block;
  }
`;

const StyledItems = styled.li`
  display: flex;
  height: 90px;
  align-items: center;
  padding: 0 10px;

  @media (${(props) => props.theme.breakpoints.midTablet}) {
    box-sizing: border-box;
    text-align: center;
    display: block;
    width: 100%;
    height: auto;
    padding: 10px 0px;
  }
`;

const StyledDropDown = styled.div`
  position: absolute;
  top: 85%;

  @media (${(props) => props.theme.breakpoints.midTablet}) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledDropdownContent = styled.ul`
  padding: 10px 20px;
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: center;
  visibility: hidden;
  width: 200px;
  line-height: 30px;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  transition: 0.3s;
  opacity: 0;
  background-color: ${(props) =>
    props.transparent ? "transparent" : props.theme.colors.secondary.main};

  li {
    text-align: left;
    padding: 10px 0 10px 10px;
    @media (${(props) => props.theme.breakpoints.tablet}) {
      text-align: center;
      transform: translateX(20px);
    }
  }
`;

const StyledLogo = styled.li`
  @media (${(props) => props.theme.breakpoints.midTablet}) {
    display: none;
  }
`;

const StyledLink = styled.a`
  ${styledLinks}
`;

const NavigationItems = (props) => {
  const navRef = React.createRef();

  let artistNavItems = null;
  if (props.artists) {
    artistNavItems = Object.values(props.artists).map((artist) => (
      <NavigationItem
        key={artist.name}
        path={{
          pathname: `/artists/${artist.slug}`,
          state: { id: artist._id },
        }}
        to={artist.name}
      >
        {artist.name}
      </NavigationItem>
    ));
  }
  let logout = null;
  if (props.user._id) {
    logout = (
      <StyledItems>
        <StyledLink href="/api/logout">Ausloggen</StyledLink>
      </StyledItems>
    );
  }

  return (
    <StyledNavigation ref={navRef}>
      <StyledItems>
        <NavigationItem path="/" to="welcome">
          Home
        </NavigationItem>
      </StyledItems>
      <StyledItems>
        <NavigationItem path="/" to="about">
          Über uns
        </NavigationItem>
      </StyledItems>
      <StyledItems>
        <NavigationItem path="/" to="artists">
          Künstler
        </NavigationItem>

        <StyledDropDown name="StyledDropDown">
          <StyledDropdownContent name="StyledDropdownContent">
            {artistNavItems}
          </StyledDropdownContent>
        </StyledDropDown>
      </StyledItems>
      <StyledLogo>
        <RouterLink
          to="/"
          onClick={() =>
            setTimeout(() => {
              Scroll.scroller.scrollTo("welcome", {
                spy: true,
                smooth: true,
                duration: 500,
                activeClass: "active",
                offset: -100,
              });
            }, 100)
          }
        >
          <Logo />
        </RouterLink>
      </StyledLogo>
      <StyledItems>
        <NavigationItem path="/" to="gallery">
          Galerie
        </NavigationItem>
      </StyledItems>
      <StyledItems>
        <NavigationItem path="/" to="testimonials">
          Erfahrungsberichte
        </NavigationItem>
      </StyledItems>
      <StyledItems>
        <NavigationItem path="/" to="contact">
          Kontakt
        </NavigationItem>
      </StyledItems>

      {logout}
    </StyledNavigation>
  );
};

const mapStateToProps = (state) => {
  return {
    artists: state.artistReducer.artists,
    user: state.userReducer.user,
  };
};
export default connect(mapStateToProps)(NavigationItems);
