import * as actions from "../actionTypes";
import axios from "axios";

export const sendEmailInit = () => {
  return {
    type: actions.SEND_EMAIL_INIT
  };
};

const sendEmailSuccess = (values) => {
  return {
    type: actions.SEND_EMAIL_SUCCESS,
    values
  };
};

const sendEmailFail = error => {
  return {
    type: actions.SEND_EMAIL_FAIL,
    error
  };
};

export const submitEmailNew = values => {
  return async dispatch => {
    dispatch(sendEmailInit());
    try {
      await axios.post("/api/emails", values);
      dispatch(sendEmailSuccess(values));
    } catch (error) {
      dispatch(sendEmailFail(error));
    }
  };
}