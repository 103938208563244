import * as actions from "../actionTypes";
import axios from "axios";

export const verifyTokenInit = () => {
  return {
    type: actions.VERIFY_TOKEN_INIT
  };
};

const verifyTokenSuccess = (values) => {
  return {
    type: actions.VERIFY_TOKEN_SUCCESS,
    values
  };
};

const verifyTokenFail = error => {
  return {
    type: actions.VERIFY_TOKEN_FAIL,
    error
  };
};

export const verifyToken = value => {
  return async dispatch => {
    dispatch(verifyTokenInit());
  try {
    await axios.post("/api/verify-token", {token: value}).then((data) => {
      if (data.status === 200) {
        dispatch(verifyTokenSuccess(data.data.success));
      }
    });
  } catch (error) {
    dispatch(verifyTokenFail(error));
  }};
}
