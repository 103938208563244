import * as actions from "../actionTypes";
import axios from "axios";

export const fetchPhotosInit = () => {
  return {
    type: actions.FETCH_PHOTOS_INIT
  };
};

const fetchPhotosSuccess = (photos, ids) => {
  return {
    type: actions.FETCH_PHOTOS_SUCCESS,
    photos,
    ids
  };
};

const fetchPhotosFail = error => {
  return {
    type: actions.FETCH_PHOTOS_FAIL,
    error
  };
};

export const fetchPhotos = () => {
  return dispatch => {
    dispatch(fetchPhotosInit());
    axios
      .get("/api/photos")
      .then(({ data: photos }) => {
        const fetchedPhotos = {};
        const fetchedPhotoIds = [];
        photos.map(photo => {
          fetchedPhotos[photo._id] = { ...photo };
          fetchedPhotoIds.push(photo._id);
          return photo;
        });

        dispatch(fetchPhotosSuccess(fetchedPhotos, fetchedPhotoIds));
      })
      .catch(error => {
        dispatch(fetchPhotosFail(error));
      });
  };
};

export const addPhotoInit = () => {
  return {
    type: actions.ADD_PHOTO_INIT
  };
};

const addPhotoSuccess = (photo, id) => {
  return {
    type: actions.ADD_PHOTO_SUCCESS,
    photo,
    id
  };
};

const addPhotoFail = error => {
  return {
    type: actions.ADD_PHOTO_FAIL,
    error
  };
};

export const submitPhotoNew = values => {
  const fd = new FormData();
  Object.entries(values).map(([key, value]) => {
    if (key !== "photo") {
      fd.append(key, value);
    }
    return key;
  });
  fd.append("photo", values.photo.file);

  return async dispatch => {
    dispatch(addPhotoInit());
    try {
      let { data: photo } = await axios.post("/api/photos", fd);
      const fetchedPhoto = {};
      let photoId = photo._id;
      photo = Object.keys(photo).map(key => {
        if (key !== "__v") {
          fetchedPhoto[key] = photo[key];
        }
        return key;
      });
      dispatch(addPhotoSuccess(fetchedPhoto, photoId));
    } catch (error) {
      dispatch(addPhotoFail(error));
    }
  };
};

export const deletePhotoInit = () => {
  return {
    type: actions.DELETE_PHOTO_INIT
  };
};

const deletePhotoSuccess = id => {
  return {
    type: actions.DELETE_PHOTO_SUCCESS,
    id
  };
};

const deletePhotoFail = error => {
  return {
    type: actions.DELETE_PHOTO_FAIL,
    error
  };
};

export const deletePhoto = ({ _id, path }) => {
  return async dispatch => {
    dispatch(deletePhotoInit());
    try {
      const { data: photo } = await axios.delete("/api/photos", {
        data: { _id, path }
      });
      dispatch(deletePhotoSuccess(photo._id));
    } catch (error) {
      dispatch(deletePhotoFail(error));
    }
  };
};
