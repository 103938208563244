import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  error: false,
  openingHours: {},
  ids: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_OPENINGHOURS_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.FETCH_OPENINGHOURS_SUCCESS:
      return {
        ...state,
        openingHours: { ...state.openingHours, ...action.openingHours },
        ids: action.ids,
        loading: false,
        error: false
      };
    case actionTypes.FETCH_OPENINGHOURS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.ADD_OPENINGHOUR_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.ADD_OPENINGHOUR_SUCCESS:
      return {
        ...state,
        openingHours: {
          [action.id]: action.openingHour,
          ...state.openingHours
        },
        ids: [action.id, ...state.ids],
        loading: false,
        error: false
      };
    case actionTypes.ADD_OPENINGHOUR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.UPDATE_OPENINGHOUR_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.UPDATE_OPENINGHOUR_SUCCESS:
      return {
        ...state,
        openingHours: {
          ...state.openingHours,
          [action.id]: {
            ...state.openingHours[action.id],
            ...action.openingHour
          }
        },
        ids: [...state.ids],
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_OPENINGHOUR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.DELETE_OPENINGHOUR_INIT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actionTypes.DELETE_OPENINGHOUR_SUCCESS:
      let updatedOpeningHours = { ...state.openingHours };
      delete updatedOpeningHours[action.id];
      let updatedIds = state.ids.filter(id => id !== action.id);
      return {
        ...state,
        openingHours: { ...updatedOpeningHours },
        ids: updatedIds,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_OPENINGHOUR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};
export default reducer;
