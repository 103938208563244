import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "../../Icon/Icon";
import styled from "styled-components";

const StyledLink = styled(RouterLink)`
  display: inline-flex;
  color: ${props =>
    props.color === "black"
      ? props.theme.colors.primary.main
      : props.color === "white"
      ? props.theme.colors.secondary.main
      : props.theme.colors.tertiary.main};

  span {
    margin-left: 20px;
  }

  &:hover img {
    transform: translateX(-8px);
    transition-duration: 0.3s;
  }
`;

const Link = props => {
  let text = null;
  if (props.text) {
    text = props.text;
  }

  let src = "/images/more.png";
  if (props.color === "white") {
    src = "/images/more_white.png";
  }
  return (
    <StyledLink to={props.url} color={props.color}>
      {text}
      <span style={{ width: "25px", height: "25px" }}>
        <Icon
          icons={props.icons}
          rotateIcon={props.rotateIcon}
          src={src}
          alt="more.png"
        />
      </span>
    </StyledLink>
  );
};

export default Link;

Link.propTypes = {
  src: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};
Link.defaultProps = {
  src: "/images/more.png",
  text: "link text",
  color: "primary"
};
