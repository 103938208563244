import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledDrawerToggle = styled.img`
    display: none;
    
    @media (${props => props.theme.breakpoints.midTablet}) {
        display: block;
        border: 3px solid ${props => props.theme.colors.tertiary.main};
        padding: 8px;
        width: 40px;
        height: 40px;
        z-index: 999999;

        &.Burger {
          transform: rotate(0deg);
          transition-duration: 0.5s;
          // background: blue;
        }
        
        &.Transition {
          transform: rotate(360deg);
          transition-duration: 0.5s;
          // background: red;
        }
    }`

const DrawerToggle = props => {
  let attachedStyle = ["Burger"];
  let burger;
  if (!props.open) {
    burger = "/images/burger.png";
  } else {
    burger = "/images/burger_close.png";
    attachedStyle = ["Transition"];
  }

  return (
    <StyledDrawerToggle className={attachedStyle.join(" ")} name="StyledDrawerToggle" onClick={props.clicked} src={burger} alt={"Burger"} />
  );
};

export default DrawerToggle;

DrawerToggle.propTypes = {
  clicked: PropTypes.func.isRequired
};
