import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import NavigationItems from "../NavigationItems/NavigationItems";
import Backdrop from "./Backdrop/Backdrop";
import classes from "./Drawer.module.css";

const StyledDrawer = styled.div`
  display: none;

  @media (${props => props.theme.breakpoints.midTablet}) {
    position: fixed;
    max-width: 70%;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: ${props => props.theme.colors.tertiary.main};
    padding: 80px 16px;
    transition: transform 0.3s ease-out;
    transition-duration: 0.5s;
  }
`;

const Drawer = props => {

  let attachedClasses = [classes.Closed];
  if (props.open) {
    attachedClasses = [classes.Open];
  }

  return (
    <>
      <Backdrop open={props.open} clicked={props.drawerToggleClicked} />
      <StyledDrawer
        className={attachedClasses.join(" ")}
        onClick={props.clicked}
      >
        <nav name="drawerNav">
          <NavigationItems />
        </nav>
      </StyledDrawer>
    </>
  );
};

export default Drawer;
Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  drawerToggleClicked: PropTypes.func.isRequired
};
