import { combineReducers } from "redux";

import photoReducer from "./photoReducer";
import instagramReducer from "./instagramReducer";
import artistReducer from "./artistReducer";
import userReducer from "./userReducer";
import openingHourReducer from "./openingHourReducer";
import campaignReducer from "./campaignReducer";
import promotionReducer from "./promotionReducer";
import emailReducer from "./emailReducer";
import verifyTokenReducer from "./verifyTokenReducer";
import { reducer as reduxForm } from "redux-form";

export default combineReducers({
  photoReducer,
  instagramReducer,
  artistReducer,
  openingHourReducer,
  userReducer,
  campaignReducer,
  promotionReducer,
  emailReducer,
  verifyTokenReducer,
  form: reduxForm
});
