import React from "react";
import Background from "../Background/Background";
import Container from "../Container/Container";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSection = styled.section`
  width: 100%;
  position: relative;
  z-index: 0;
`;

const Section = props => {
  return (
    <StyledSection>
      <Background bgcolor={props.bgcolor} bgimage={props.bgimage}>
        <Container
          position={props.position}
          contentFullScreen={props.contentFullScreen}
        >
          {props.children}
        </Container>
      </Background>
    </StyledSection>
  );
};

export default Section;
Section.propTypes = {
  bgcolor: PropTypes.string.isRequired,
  bgimage: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  contentFullScreen: PropTypes.bool.isRequired
};
Section.defaultProps = {
  bgcolor: "transparent",
  bgimage: "",
  position: "static",
  contentFullScreen: false
};
