import * as actions from "../actionTypes";
import axios from "axios";

export const fetchOpeningHoursInit = () => {
  return {
    type: actions.FETCH_OPENINGHOURS_INIT
  };
};

const fetchOpeningHoursSuccess = (openingHours, ids) => {
  return {
    type: actions.FETCH_OPENINGHOURS_SUCCESS,
    openingHours,
    ids
  };
};

const fetchOpeningHourFail = error => {
  return {
    type: actions.FETCH_OPENINGHOURS_FAIL,
    error
  };
};

export const fetchOpeningHours = () => dispatch => {
  dispatch(fetchOpeningHoursInit());
  axios
    .get(`/api/openingHours`)
    .then(({ data }) => {
      const fetchedOpeningHours = {};
      const fetchedOpeningHourIds = [];
      data.map(openingHour => {
        fetchedOpeningHours[openingHour._id] = { ...openingHour };
        fetchedOpeningHourIds.push(openingHour._id);
        return openingHour;
      });

      dispatch(
        fetchOpeningHoursSuccess(fetchedOpeningHours, fetchedOpeningHourIds)
      );
    })
    .catch(error => {
      dispatch(fetchOpeningHourFail(error));
    });
};

export const addOpeningHourInit = () => {
  return {
    type: actions.ADD_OPENINGHOUR_INIT
  };
};

const addOpeningHourSuccess = (openingHour, id) => {
  return {
    type: actions.ADD_OPENINGHOUR_SUCCESS,
    openingHour,
    id
  };
};

const addOpeningHourFail = error => {
  return {
    type: actions.ADD_OPENINGHOUR_FAIL,
    error
  };
};

export const submitOpeningHourNew = values => {
  return async dispatch => {
    dispatch(addOpeningHourInit());
    try {
      let { data: openingHour } = await axios.post("/api/openingHours", values);
      const fetchedOpeningHour = {};
      let openingHourId = openingHour._id;
      openingHour = Object.keys(openingHour).map(key => {
        if (key !== "__v") {
          fetchedOpeningHour[key] = openingHour[key];
        }
        return key;
      });
      dispatch(addOpeningHourSuccess(fetchedOpeningHour, openingHourId));
    } catch (error) {
      dispatch(addOpeningHourFail(error));
    }
  };
};

export const updateOpeningHourInit = () => {
  return {
    type: actions.UPDATE_OPENINGHOUR_INIT
  };
};

const updateOpeningHourSuccess = (openingHour, id) => {
  return {
    type: actions.UPDATE_OPENINGHOUR_SUCCESS,
    openingHour,
    id
  };
};

const updateOpeningHourFail = error => {
  return {
    type: actions.UPDATE_OPENINGHOUR_FAIL,
    error
  };
};

export const submitOpeningHourUpdate = values => {
  return async dispatch => {
    dispatch(updateOpeningHourInit());
    try {
      let { data: openingHour } = await axios.put("/api/openingHours/", values);
      const fetchedOpeningHour = {};
      let openingHourId = values._id;
      openingHour = Object.keys(openingHour).map(key => {
        if (key !== "__v") {
          fetchedOpeningHour[key] = openingHour[key];
        }
        return key;
      });
      dispatch(updateOpeningHourSuccess(fetchedOpeningHour, openingHourId));
    } catch (error) {
      dispatch(updateOpeningHourFail(error));
    }
  };
};

export const deleteOpeningHourInit = () => {
  return {
    type: actions.DELETE_OPENINGHOUR_INIT
  };
};

const deleteOpeningHourSuccess = id => {
  return {
    type: actions.DELETE_OPENINGHOUR_SUCCESS,
    id
  };
};

const deleteOpeningHourFail = error => {
  return {
    type: actions.DELETE_OPENINGHOUR_FAIL,
    error
  };
};

export const deleteOpeningHour = ({ _id }) => {
  return async dispatch => {
    dispatch(deleteOpeningHourInit());
    try {
      let { data: openingHour } = await axios.delete("/api/openingHours", {
        data: { _id }
      });
      let openingHourId = openingHour._id;
      dispatch(deleteOpeningHourSuccess(openingHourId));
    } catch (error) {
      dispatch(deleteOpeningHourFail(error));
    }
  };
};
