import React from "react";
import Image from "../../UI/Image/Image";
import styled from "styled-components";

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 70%;
  margin-bottom: 20px;
  p {
    color: ${(props) => props.theme.colors.primary.main};
    margin-left: 10px;
  }
  & a > address {
    cursor: alias;
  }
`;

const StyledIcon = styled.div`
  width: inherit;
  display: flex;
  align-items: center;
`;

const StyledH2 = styled.h2`
  width: fit-content;
  font-family: inherit;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  border-bottom: 3px solid ${(props) => props.theme.colors.tertiary.main};
  color: ${(props) => props.theme.colors.tertiary.main};
  margin-bottom: 20px;
`;

const ContactItems = (props) => (
  <div>
    <StyledH2>Adresse</StyledH2>
    <StyledItem>
      <StyledIcon>
        <Image nowidth_height src="/images/address.png" alt="address" />
      </StyledIcon>
      <a
        href="https://g.page/td-art-tattoo-studio?share"
        target="_blank"
        rel="noopener noreferrer"
      >
        <address>Carl-Schurz-Straße 15 50321 Brühl, Deutschland</address>
      </a>
    </StyledItem>
    <StyledItem>
      <StyledIcon>
        <Image nowidth_height src="/images/email.png" alt="email" />
      </StyledIcon>
      <a href="mailto:titarevjuggler@gmail.com">
        <p>titarevjuggler@gmail.com</p>
      </a>
    </StyledItem>
    <StyledItem>
      <StyledIcon>
        <Image nowidth_height src="/images/phone.png" alt="phone" />
      </StyledIcon>
      <a href="tel:4915753638272" rel="nofollow">
        <p>+49 157 536 38 272</p>
      </a>
    </StyledItem>
  </div>
);

export default ContactItems;
