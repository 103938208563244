import * as actions from "../actionTypes";
import axios from "axios";

export const fetchPromotionsInit = () => {
  return {
    type: actions.FETCH_PROMOTIONS_INIT
  };
};

const fetchPromotionsSuccess = (promotions, ids) => {
  return {
    type: actions.FETCH_PROMOTIONS_SUCCESS,
    promotions,
    ids
  };
};

const fetchPromotionFail = error => {
  return {
    type: actions.FETCH_PROMOTIONS_FAIL,
    error
  };
};

export const fetchPromotions = () => dispatch => {
  dispatch(fetchPromotionsInit());
  axios
    .get(`/api/promotions`)
    .then(({ data }) => {
      const fetchedPromotions = {};
      const fetchedPromotionIds = [];
      data.map(promotion => {
        fetchedPromotions[promotion._id] = { ...promotion };
        fetchedPromotionIds.push(promotion._id);
        return promotion;
      });

      dispatch(fetchPromotionsSuccess(fetchedPromotions, fetchedPromotionIds));
    })
    .catch(error => {
      dispatch(fetchPromotionFail(error));
    });
};

export const addPromotionInit = () => {
  return {
    type: actions.ADD_PROMOTION_INIT
  };
};

const addPromotionSuccess = (promotion, id) => {
  return {
    type: actions.ADD_PROMOTION_SUCCESS,
    promotion,
    id
  };
};

const addPromotionFail = error => {
  return {
    type: actions.ADD_PROMOTION_FAIL,
    error
  };
};

export const submitPromotionNew = values => {
  return async dispatch => {
    dispatch(addPromotionInit());
    try {
      let { data: promotion } = await axios.post("/api/promotions", values);
      const fetchedPromotion = {};
      let promotionId = promotion._id;
      promotion = Object.keys(promotion).map(key => {
        if (key !== "__v") {
          fetchedPromotion[key] = promotion[key];
        }
        return key;
      });
      dispatch(addPromotionSuccess(fetchedPromotion, promotionId));
    } catch (error) {
      dispatch(addPromotionFail(error));
    }
  };
};

export const updatePromotionInit = () => {
  return {
    type: actions.UPDATE_PROMOTION_INIT
  };
};

const updatePromotionSuccess = (promotion, id) => {
  return {
    type: actions.UPDATE_PROMOTION_SUCCESS,
    promotion,
    id
  };
};

const updatePromotionFail = error => {
  return {
    type: actions.UPDATE_PROMOTION_FAIL,
    error
  };
};

export const submitPromotionUpdate = values => {
  return async dispatch => {
    dispatch(updatePromotionInit());
    try {
      let { data: promotion } = await axios.put("/api/promotions/", values);
      const fetchedPromotion = {};
      let promotionId = values._id;
      promotion = Object.keys(promotion).map(key => {
        if (key !== "__v") {
          fetchedPromotion[key] = promotion[key];
        }
        return key;
      });
      dispatch(updatePromotionSuccess(fetchedPromotion, promotionId));
    } catch (error) {
      dispatch(updatePromotionFail(error));
    }
  };
};

export const deletePromotionInit = () => {
  return {
    type: actions.DELETE_PROMOTION_INIT
  };
};

const deletePromotionSuccess = id => {
  return {
    type: actions.DELETE_PROMOTION_SUCCESS,
    id
  };
};

const deletePromotionFail = error => {
  return {
    type: actions.DELETE_PROMOTION_FAIL,
    error
  };
};

export const deletePromotion = ({ _id }) => {
  return async dispatch => {
    dispatch(deletePromotionInit());
    try {
      let { data: promotion } = await axios.delete("/api/promotions", {
        data: { _id }
      });
      let promotionId = promotion._id;
      dispatch(deletePromotionSuccess(promotionId));
    } catch (error) {
      dispatch(deletePromotionFail(error));
    }
  };
};
