import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledBackground = styled.div`
  background-image: linear-gradient(
    ${props => props.color1},
    ${props => props.color2}
  );
`;

const TopBackground = props => (
  <StyledBackground color1={props.color1} color2={props.color2}>
    {props.children}
  </StyledBackground>
);

export default TopBackground;

TopBackground.propTypes = {
  color1: PropTypes.string.isRequired,
  color2: PropTypes.string.isRequired
};

TopBackground.defaultProps = {
  color1: "rgb(0,0,0)",
  color2: "#5C0000"
};
