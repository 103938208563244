import * as actions from "../actionTypes";
import axios from "axios";

export const fetchInstagramInit = () => {
  return {
    type: actions.FETCH_INSTAGRAM_INIT
  };
};

const fetchInstagramSuccess = (instagrams, ids) => {
  return {
    type: actions.FETCH_INSTAGRAM_SUCCESS,
    instagrams,
    ids
  };

};

const fetchInstagramFail = error => {
  return {
    type: actions.FETCH_INSTAGRAM_FAIL,
    error
  };
};

export const fetchInstagram = () => {
  return async dispatch => {
    dispatch(fetchInstagramInit());
    try {
      let { data: instagrams } = await axios.get(`/api/instagrams`);
      const ids = Object.keys(instagrams);
      dispatch(fetchInstagramSuccess(instagrams, ids));
    } catch (error) {
      dispatch(fetchInstagramFail(error));
    }
  };
};
