import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledContainer = styled.div`
  margin: 0 auto;
  height: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: ${props => props.position};
  max-width: ${props => (props.contentFullScreen ? "100%" : "1200px")};

  @media (${props => props.theme.breakpoints.mobileL}) {
    flex-direction: column;
  }
`;

const Container = props => (
  <StyledContainer
    position={props.position}
    className="container"
    contentFullScreen={props.contentFullScreen}
  >
    {props.children}
  </StyledContainer>
);

export default Container;
Container.propTypes = {
  position: PropTypes.string.isRequired,
  contentFullScreen: PropTypes.bool.isRequired
};
Container.defaultProps = {
  position: "static",
  contentFullScreen: false
};
