import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 450px;
  position: relative;
  background-color: #fff;
  z-index: 3;
  & iframe {
    position: relative;
    z-index: 3;
  }
`;

const Map = () => (
  <Container>
    <iframe
      title="td-art-tattoo"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10081.132188555453!2d6.9014351!3d50.8259209!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb0bf529f55c2f27e!2sTD%20Art%20Tattoo%20Studio!5e0!3m2!1sen!2sde!4v1581347376933!5m2!1sen!2sde"
      width="100%"
      height="450"
      style={{ border: 0 }}
      allowFullScreen=""
    ></iframe>
  </Container>
);

export default Map;
