import React, { Component } from "react";
import ReactGA from "react-ga";
import ReCAPTCHA from "react-google-recaptcha";

import { updateObject, checkValidity } from "./utility";

import { connect } from "react-redux";
import * as actions from "../../../store/actions";

import Button from "../../UI/Button/Button";
import Spinner from "../../UI/Spinner/Spinner";
import Input from "./Input/Input";

import styled from "styled-components";

const StyledForm = styled.div`
  text-align: left;
`;

const StyledH2 = styled.h2`
  width: fit-content;
  font-family: inherit;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  border-bottom: 3px solid ${(props) => props.theme.colors.tertiary.main};
  color: ${(props) => props.theme.colors.tertiary.main};
  margin-bottom: 20px;
`;

const StyledNotification = styled.div`
  padding: 10px 0;
  background-color: white;
  text-align: center;
`;

const StyledNotificationP = styled.p`
  color: ${(props) => props.theme.colors.primary.dark};
`;

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactForm: {
        name: {
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ihr Name",
            label: "Name",
          },
          value: "",
          valueType: "name",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
        },
        email: {
          elementType: "input",
          elementConfig: {
            type: "email",
            placeholder: "E-Mail",
            label: "E-Mail",
          },
          value: "",
          valueType: "email",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
        },
        phone: {
          elementType: "input",
          elementConfig: {
            type: "tel",
            placeholder: "Telefon",
            label: "Telefon",
          },
          value: "",
          valueType: "phone",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
        },
        message: {
          elementType: "textarea",
          elementConfig: {
            type: "textarea",
            placeholder: "Eine Nachricht hinterlassen",
            label: "Ihre Nachricht",
          },
          value: "",
          valueType: "text",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
        },
      },
      formIsValid: false,
      tokenValid: false,
      send: false,
    };

    this.captchaRef = React.createRef();
  }

  formHandler = (event) => {
    event.preventDefault();

    const formData = {};

    for (let key in this.state.contactForm) {
      formData[key] = this.state.contactForm[key].value;
    }

    if (this.state.tokenValid) {
      this.props.onSubmitEmailNew(formData);

      this.setState({ contactForm: {} });
      this.setState({ send: true });
    }

    setTimeout(() => {
      this.setState({ send: false });
      this.setState({ contactForm: { ...this.state.contactForm } });
    }, 1500);
  };

  validToken = async (token) => {
    if (token !== "") {
      await this.props.verifyToken(token);
      this.captchaRef.current.reset();
    }

    if (this.props.isVerified) {
      this.setState({ tokenValid: this.props.isVerified });
      this.state.tokenValid = this.props.isVerified;
    }
  };

  inputChangeHandler = (event, name) => {
    const updatedFormElement = updateObject(this.state.contactForm[name], {
      value: event.target.value,
      valid: checkValidity(
        event.target.value,
        this.state.contactForm[name].validation
      ),
      touched: true,
    });
    const updatedOrderForm = updateObject(this.state.contactForm, {
      [name]: updatedFormElement,
    });
    let formIsValid = true;
    for (let key in updatedOrderForm) {
      formIsValid = updatedOrderForm[key].valid && formIsValid;
    }

    this.setState({ contactForm: updatedOrderForm, formIsValid });
  };

  render() {
    const formElementsArray = Object.keys(this.state.contactForm).map(
      (key) => ({
        ...this.state.contactForm[key],
        name: key,
      })
    );

    let form = (
      <form id="form">
        {formElementsArray.map((el) => (
          <Input
            key={el.name}
            elementType={el.elementType}
            elementConfig={el.elementConfig}
            value={el.value}
            valueType={el.valueType}
            changed={(e) => this.inputChangeHandler(e, el.name)}
            invalid={!el.valid}
            shouldValidate={!!el.validation}
            touched={el.touched}
            label={el.elementConfig.label}
          />
        ))}
        {!this.props.send ? (
          ""
        ) : (
          <StyledNotification>
            <StyledNotificationP>Danke!</StyledNotificationP>
          </StyledNotification>
        )}
        <Button
          bgColor="tertiary"
          fontColor="primary"
          clicked={(event) => {
            this.formHandler(event);

            ReactGA.event({
              category: "Contact",
              action: "Contact Form Send pressed",
            });
          }}
          disabled={!this.state.tokenValid || !this.state.formIsValid}
        >
          Nachricht senden
        </Button>
      </form>
    );

    if (this.props.loading) {
      form = <Spinner />;
    }

    return (
      <StyledForm>
        <StyledH2>Kontaktieren Sie uns</StyledH2>
        {form}
        <br />
        {!this.state.tokenValid ? (
          <ReCAPTCHA
            // sitekey={process.env.REACT_APP_SITE_KEY}
            sitekey="6LeRtTAjAAAAAG392z3tegGnGLxkR_ZJCdqxJ3cH"
            ref={this.captchaRef}
            onChange={this.validToken}
          />
        ) : null}
      </StyledForm>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    verifyToken: (token) => dispatch(actions.verifyToken(token)),
    onSubmitEmailNew: (values) => dispatch(actions.submitEmailNew(values)),
  };
};

const mapStateToProps = (state) => {
  return {
    isVerified: state.verifyTokenReducer.isVerified,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
