import React from "react";
import { ThemeProvider } from "styled-components";

export const theme = {
  colors: {
    primary: {
      main: "#fff",
      dark: "#ff103d"
    },
    secondary: {
      light: "#686868",
      main: "#000"
    },
    tertiary: {
      light: "#fff",
      main: "#ff103d",
      dark: "#E60024"
    },
    transparent: {
      main: "transparent"
    },
    greyBg: {
      main: "#e8e8e8"
    },
    lightblack: "rgba(0,0,0,.79)"
  },
  iconColors: {
    white: "brightness(0) invert(1)",
    black: "brightness(0) invert(0) contrast(1000%)",
    neonred:
      "invert(27%) sepia(82%) saturate(6970%) hue-rotate(340deg) brightness(99%) contrast(104%)"
  },
  bgimage: {
    img1: "/images/clem1024.jpg",
    img2: "/images/allef1200.jpg",
    artist: "/images/artist_bg.jpg"
  },
  fonts: ["Abril Fatface"],
  fontWeight: {
    large: "700"
  },
  lineHeight: {
    large: "54px"
  },
  fontSizes: {
    smallest: "1.125em", // 18px
    small: "1.8125em", // 29px
    smallL: "1.5em", // 24px
    medium: "2.4375em", // 39px
    large: "3.0625em", // 49px
    largest: "3.6875em" // 59px
  },
  breakpoints: {
    minMobile: "min-width: 310px",
    midMobile: "max-width: 480px",
    midMobilePortrait: "max-width: 570px",
    maxMobile: "max-width: 896px",
    maxMobilemin: "min-width: 896px",
    minTablet: "max-width: 600px",
    midTablet: "max-width: 768px",
    maxTablet: "max-width: 1024px",
    minTabletL: "min-width: 1100px",
    mindesktop: "min-width: 1350px",

    // hero query
    mintablet: "min-width: 768px",
    minmobileLandscape: "min-width: 812px",
    minmobileLandscapelg: "min-width: 846px",
    mintabletmd: "min-width: 896px",
    tabletPro: "min-width: 1024px",
    minlaptop: "min-width: 1200px",
    laptopmd: "min-width: 1400px",
    laptoplg: "min-width: 1900px",
    desktop: "min-width: 2500px",
    // hero query
    // hero title query
    minPortrait: "max-width: 420px",
    minPortraitMD: "min-width: 421px",
    minPortraitLG: "min-width: 450px",
    // hero title query

    // Styledtoolbar
    mobileLandscapeHeader: "min-width: 750px",
    // Styledtoolbar

    mobilePortraitS: "max-width: 345px",
    mobilePortraitM: "max-width: 375px",
    mobileS: "max-width: 550px",
    mobileM: "max-width: 558px",
    mobileMmin: "min-width: 558px",
    mobileML: "max-width: 600px",
    mobileLandscape: "max-width: 680px",
    mobileL: "max-width: 736px",
    tablet: "max-width: 750px",
    tabletSM: "max-width: 846px",
    // tableSMmin: "min-width: 846px",
    tableM: "max-width: 900px",
    tabletL: "max-width: 1024px",
    laptop: "max-width: 1250px",
    laptopL: "max-width: 1650px",
    maxdesktop: "max-width: 2560px"
  },
  maxWidth: {
    mobileS: "50%",
    mobileM: "100%",
    mobileL: "100%",
    tablet: "100%",
    laptop: "1200px",
    laptopL: "1200px",
    desktop: "1200px"
  },
  drawer: {
    burger: "/images/burger.png",
    burgerClose: "/images/burger_close.png"
  },
  resizes: {
    resizeLogo: "60px"
  },
  contactVideo: {
    display: "flex"
  },
  socialLinks: [
    {
      name: "facebook",
      href: "https://www.facebook.com/TD-art-Tattoo-Studio-2050633005253133/",
      src: "/images/facebook.png"
    },
    {
      name: "instagram",
      href: "https://www.instagram.com/tdarttattoo/",
      src: "/images/instagram.png"
    }
  ]
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
