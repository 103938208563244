import React from "react";
import Scroll, { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import styledLinks from "../styledLinks";

const StyledLink = styled(RouterLink)`
  ${styledLinks}
`;

const NavigationItem = props => (
  <>
    <ScrollLink
      to={props.to}
      activeClass="active-ghost"
      spy={true}
      offset={-100}
    ></ScrollLink>
    <StyledLink
      to={props.path}
      onClick={() =>
        setTimeout(() => {
          Scroll.scroller.scrollTo(props.to, {
            spy: true,
            smooth: true,
            duration: 500,
            activeClass: "active",
            offset: -100
          });
        }, 100)
      }
    >
      {props.children}
    </StyledLink>
  </>
);

export default NavigationItem;
