import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  error: false,
  isVerified: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VERIFY_TOKEN_INIT:
      return {
        ...state,
        loading: true,
        error: false,
        isVerified: false,
      };
    case actionTypes.VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isVerified: true,
      };
    case actionTypes.VERIFY_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};
export default reducer;
