import React from "react";
import Icon from "../../Icon/Icon";
import styled from "styled-components";
import ReactGA from "react-ga";

const StyledLink = styled.a`
  padding: 0 5px;
`;

const SocialMediaItem = props => {
  return (
    <StyledLink
      href={props.href}
      target="_blank"
      onClick={() => {
        ReactGA.event({
          category: "SocialMedia",
          action: `Artistpage ${props.href} pressed`
        });
      }}
    >
      <Icon
        rotateIcon={props.rotateIcon}
        color={props.color}
        iconColor={props.iconColor}
        src={props.src}
        alt="social icons"
        nowidth_height
      />
    </StyledLink>
  );
};

export default SocialMediaItem;
